const LOTTIE_CACHE = new Map<string, object>();

export async function preloadLottieFiles(paths: string[]): Promise<void> {
  await Promise.all(
    paths.map(async path => {
      if (!LOTTIE_CACHE.has(path)) {
        const response = await fetch(path);
        const data = await response.json();
        LOTTIE_CACHE.set(path, data);
        return data;
      }
      return LOTTIE_CACHE.get(path);
    })
  );
}

export function getLottieData(path: string): object | undefined {
  return LOTTIE_CACHE.get(path);
}
