/**
 * Updates menu items' locked and new status based on their groups.
 * Iterates over an array of menu items, each potentially having `isLocked`, `isNew`, and `groups`.
 * A menu item is considered locked if it or all items in its groups are locked. It's considered new if it or any item in its groups is new.
 *
 * @param {Object[]} items - Array of menu items with optional `isLocked`, `isNew`, and `groups` properties.
 * @returns {Object[]} Processed menu items with updated `isLocked` and `isNew` statuses.
 */
export default function processMenuItems(items) {
  return items.map(
    ({ isLocked, isNew, canStartFreeTrial, displayNewText, groups, ...attributes }) => ({
      ...attributes,
      isLocked: isLocked || groups?.every(({ items }) => items.every(({ isLocked }) => isLocked)),
      isNew: isNew || groups?.some(({ items }) => items.some(({ isNew }) => isNew)),
      canStartFreeTrial:
        canStartFreeTrial ||
        groups?.every(({ items }) => items.every(({ canStartFreeTrial }) => canStartFreeTrial)),
      displayNewText:
        displayNewText ||
        groups?.some(({ items }) => items.some(({ displayNewText }) => displayNewText)),
      groups,
    })
  );
}
